// 分页展示问题   例:第二页有一条数据 删除后展示第一页数据
const pageRules = (dataLength, deleteLength, page) => {
  let pageNum = page
  if (dataLength === deleteLength) {
    if (page !== 1) {
      pageNum = page - 1
    }
  }
  console.log(pageNum)
  return pageNum
}
export default {
  pageRules
}
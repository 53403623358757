import { fetchApi } from "@/utils/axios";
import URL from "@/api/urlConfig"
import http from "@/utils/axios"
import store from "@/store";
/**
 * @description: 招聘信息列表
 * @param {*} data page:当前页 size:当前条数 jobName:岗位名称 jobTypeId:岗位类型 projectId:项目ID enterpriseName:企业名称
 * @return {*}
 */
export const getRecruitmentList = (data) => {
  return fetchApi(URL.GET_RECRUITMENT_LIST, data, 'get','params',true)
}

/**
 * @description:  新增招聘信息
 * @param {*} data
 * @return {*}
 */
export const addRecruitment = (data) => {
  return fetchApi(URL.RECRUITMENT_ADD, data, 'post', 'data')
}

/**
 * @description:  编辑招聘信息
 * @param {*} data
 * @return {*}
 */
export const editRecruitment = (data) => {
  return fetchApi(URL.RECRUITMENT_EDIT, data, 'put', 'data')
}

/**
 * @description: 招聘信息详情
 * @param {*} recruitmentId 招聘信息ID
 * @return {*}
 */
export const recruitmentDetail = (recruitmentId) => {
  return fetchApi(URL.RECRUITMENT_DETAIL + '/' + recruitmentId, null, 'get')
}
// 页面入口下拉列表
export const recruitmentSelectList = () => {
  return fetchApi(URL.RECRUITMENT_SELECT_LIST, null, 'get')
}
/**
 * @description: 删除招聘信息
 * @param {*} recruitmentId 招聘信息id
 * @return {*}
 */
export const recruitmentDelete = (recruitmentId) => {
  return fetchApi(URL.RECRUITMENT_DELETE + '/' + recruitmentId, null, 'delete')
}

/**
 * @description: 获取所属项目
 * @return {*}
 */
// 获取项目名称
export const getProjectList = () => {
  return fetchApi(URL.BINDING_PROJECT_LIST, null)
}
/**
 * @description: 搜索企业名称
 * @param {*} data enterprise_name:企业名称 page:0 size:100
 * @return {*}
 */
export const findEnterpriseInfoName = (data) => {
  return fetchApi(URL.FIND_ENTERPRISE_INFO_BY_NAME, data, 'post', 'data')
}
/**
 * @description: 招聘信息发布/取消发布
 * @param {*} data recruitId:招聘ID publishStatus:发布状态
 * @return {*}
 */
export const recruitmentPublish = (data) => {
  return fetchApi(URL.RECRUITMENT_PUBLISH, data, 'put', 'data')
}
/**
 * @description: 简历投递列表
 * @param {*} data
 * @return {*}
 */
export const resumeList = (data) => {
  return fetchApi(URL.RESUME_LIST, data, 'post', 'data',true)
}
/**
 * @description: 简历投递最早时间
 * @return {*}
 */
export const getDeliveryDate = () => {
  return fetchApi(URL.GET_DELIVERY_DATE, null, 'get')
}
/**
 * @description: 查询简历投递企业
 * @param {*} data page:当前页 size:当前条数 enterpriseName:企业名称
 * @return {*}
 */
export const resumeFindEnterprise = (data) => {
  return fetchApi(URL.RESUME_FIND_ENTERPRISE, data, 'get')
}
/**
 * @description: 重新发送邮件
 * @param {*} id 主键ID
 * @return {*}
 */
export const resumeResend = (id) => {
  return fetchApi(URL.RESUME_RESEND, id, 'post')
}
/**
 * @description: 下载简历
 * @param {*} id 主键ID
 * @return {*}
 */
export const downLoadResume = (id) => {
  return http({
    url: URL.DOWNLOAD_RESUME,
    timeout: 120000,
    responseType: 'blob',
    method: 'get',
    params: id
  })

}
/**
 * @description: 批量下载
 * @param {*} data
 * @return {*}
 */
export const batchDownloadResume = (data) => {
  return http({
    url: URL.BATCH_DOWNLOAD_RESUME,
    responseType: 'blob',
    timeout: 120000,
    method: 'post',
    data: data
  })
}
/**
 * @description: 批量导出
 * @param {*} data
 * @return {*}
 */
export const batchExportResume = (data) => {
  let roleId = store.state.common.currentMenuRoleId
  return http({
    url: URL.BATCH_EXPORT_RESUME,
    responseType: 'blob',
    timeout: 120000,
    method: 'post',
    data: {...data,roleId}
  })
}

/**
 * @description: 查询招聘专场列表
 * @param {*} data page:当前页 size:当前条数 specialName:专场名称 state:状态
 * @return {*}
 */
export const getSpecialList = (data) => {
  return fetchApi(URL.GET_SPECIAL_LIST, data, 'post', 'data',true)
}

/**
 * @description:  新增招聘专场
 * @param {*} data
 * @return {*}
 */
export const addSpecial = (data) => {
  return fetchApi(URL.SPECIAL_ADD, data, 'post', 'data')
}

/**
 * @description:  编辑招聘专场
 * @param {*} data
 * @return {*}
 */
export const editSpecial = (data) => {
  return fetchApi(URL.SPECIAL_EDIT, data, 'put', 'data',true)
}

/**
 * @description: 招聘专场详情
 * @param {*} recruitmentId 招聘信息ID
 * @return {*}
 */
export const specialDetail = (specialId) => {
  return fetchApi(URL.SPECIAL_DETAIL + '/' + specialId, null, 'get')
}

/**
 * @description: 删除招聘专场信息
 * @param {*} specialId:招聘专场id
 * @return {*}
 */
export const specialDelete = (specialId) => {
  return fetchApi(URL.SPECIAL_DELETE + '/' + specialId, null, 'delete')
}

/**
 * @description: 招聘专场开启/关闭
 * @param {*} data specialId:招聘专场id state:状态
 * @return {*}
 */
export const specialOpen = (data) => {
  return fetchApi(URL.SPECIAL_OPEN, data, 'put', 'data')
}

/**
 * @description: 查询招聘专场的模块信息
 * @param {*} specialId:招聘专场id
 * @return {*}
 */
export const getSpecialModuleList = (specialId) => {
  return fetchApi(URL.GET_SPECIAL_MODULE_LIST + '/' + specialId, null, 'get')
}

/**
 * @description: 查询招聘专场职位列表
 * @param {*} data page:当前页 size:当前条数 jobName:职位名称 enterpriseName:企业名称 specialId:专场ID
 * @return {*}
 */
export const getSpecialPositionList = (data) => {
  return fetchApi(URL.GET_SPECIAL_POSITION_LIST, data, 'post', 'data',true)
}

/**
 * @description:  新增招聘专场职位
 * @param {*} data
 * @return {*}
 */
export const addSpecialPosition = (data) => {
  return fetchApi(URL.SPECIAL_POSITION_ADD, data, 'post', 'data')
}

/**
 * @description:  绑定专场查询职位列表
 * @param {*} data
 * @return {*}
 */
export const getSpecialModulePositionList = (data) => {
  return fetchApi(URL.GET_SPECIAL_MODULE_POSITION_LIST, data, 'post', 'data',true)
}

/**
 * @description: 删除招聘专场职位信息
 * @param {*} positionId:专场职位id
 * @return {*}
 */
export const specialPositionDelete = (positionId) => {
  return fetchApi(URL.SPECIAL_POSITION_DELETE + '/' + positionId, null, 'delete')
}
export const SPECIAL_POSITION_GETPROJECT = () => {
  return fetchApi(URL.SPECIAL_POSITION_GETPROJECT , {}, 'get','params',true)
}
export const SPECIAL_POSITION_GETENTERPRISE = (data) => {
  return fetchApi(URL.SPECIAL_POSITION_GETENTERPRISE , data, 'post', 'data',true)
}
// 判断是否为租户
export const isTenant = (data) => {
  return fetchApi(URL.IS_TENANT , data, 'get', 'data')
}
// 顺义获取项目接口
export function getShunyiAllProject(data) {
  return fetchApi(URL.GET_SHUNYI_ALL_PROJECTLIST, data, 'post', 'data',true);
}